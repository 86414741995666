const AppleMusic = ({
  color = "#4d3825",
  width = "21.89",
  height = false,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={!height && width}
      height={height}
      viewBox="0 0 21.888 21.888"
    >
      <path
        id="Path"
        d="M3.283,21.888A3.283,3.283,0,0,1,0,18.6V3.283A3.283,3.283,0,0,1,3.283,0H18.6a3.283,3.283,0,0,1,3.283,3.283V18.6A3.283,3.283,0,0,1,18.6,21.888Zm2.677-3.4a3.387,3.387,0,0,0,1.051-.19c1.282-.47,1.5-1.71,1.5-2.95V8.507a.454.454,0,0,1,.427-.47l5.9-1.2q.47-.086.513.427v5.215q0,.641-1.924.855c-2.437.385-2.052,4.489,1.282,3.377,1.282-.47,1.5-1.71,1.5-2.95v-10s0-.855-.727-.641l-7.267,1.5a.712.712,0,0,0-.556.769v8.678q0,.641-1.924.855A1.79,1.79,0,0,0,5.96,18.487Z"
        fill={color}
      />
    </svg>
  );
};

export default AppleMusic;
