import Musicfront from "../icons/musicfront";
import Spotify from "../icons/spotify";
import AppleMusic from "../icons/appleMusic";
import Youtube from "../icons/youtube";
import Facebook from "../icons/facebook";
import Instagram from "../icons/instagram";
import { File, Files, Mail } from "lucide-react";

const About = () => {
  const Links = () => {
    return (
      <>
        <div className="flex items-center mb-8 space-x-6">
          <a href="https://www.facebook.com/blackgasolines" className="transition-all duration-300 hover:scale-105">
            <Facebook />
          </a>
          <a href="https://www.instagram.com/theblackgasolines/" className="transition-all duration-300 hover:scale-105">
            <Instagram />
          </a>
          <a href="https://open.spotify.com/artist/0MD4KrWissGUdxRxeaLbBp" className="transition-all duration-300 hover:scale-105">
            <Spotify />
          </a>
          <a href="https://music.apple.com/be/artist/the-black-gasolines" className="transition-all duration-300 hover:scale-105">
            <AppleMusic />
          </a>
          <a href="https://www.youtube.com/@theblackgasolines" className="transition-all duration-300 hover:scale-105">
            <Youtube />
          </a>
          <a href="https://www.musicfront.app/shops/theblackgasolines" className="transition-all duration-300 hover:scale-105">
            <Musicfront />
          </a>
        </div>
        <a
          href="mailto:info@theblackgasolines.com"
          className="flex items-center mb-4 space-x-4"
        >
          <Mail />
          <p className="uppercase text-primary font-title">
            info@theblackgasolines.com
          </p>
        </a>
        <a href="https://drive.google.com/drive/folders/1bNzNyXO__LTkp4TlVDSJW01JU7vXq4cX?usp=sharing" className="flex items-center space-x-4">
          <Files />
          <p className="uppercase text-primary font-title">Presskit</p>
        </a>
      </>
    );
  };

  return (
    <div
      id="about"
      className="relative py-32 bg-center bg-no-repeat bg-cover bg-secundary"
      style={{ backgroundImage: "url(/images/about.webp)" }}
    >
      <div className="absolute top-0 left-0 w-full h-full opacity-80 bg-secundary" />
      <div className="relative">
        <div className="container text-primary font-text">
          <h2 className="mb-8 text-4xl uppercase lg:text-7xl font-title">
            About
          </h2>
          <div className="grid grid-cols-12 gap-8 2xl:gap-32">
            <div className="col-span-12 lg:col-span-6">
              <p className="mb-4">
                <span className="font-bold">
                  The Black Gasolines bring a breath of fresh air to the
                  Theatrical Art Rock genre, putting the classic sounds of the
                  70s and 80s into a contemporary context.
                </span>{" "}
                With a string of singles and a successful debut album, the band
                has firmly established itself, with more than 300 live
                performances on their tally. These included playing at a
                sold-out Charlatan and supporting emerging and established acts
                such as <span className="font-bold">RHEA, Hideous</span> and{" "}
                <span className="font-bold">The Sore Losers</span>. They also
                already <span className="font-bold">toured Europe</span>, with
                stops in <span className="font-bold">Belgium</span>, the{" "}
                <span className="font-bold">UK</span>,{" "}
                <span className="font-bold">Portugal</span>,{" "}
                <span className="font-bold">Spain</span>, the{" "}
                <span className="font-bold">Netherlands</span>,{" "}
                <span className="font-bold">Luxembourg</span> and{" "}
                <span className="font-bold">France</span>.
              </p>
              <p className="mb-8">
                The band was founded by{" "}
                <span className="font-bold">Bradley Remorie</span> and{" "}
                <span className="font-bold">Lukas Debeerst</span>, originating
                from <span className="font-bold">Kortrijk</span>. With{" "}
                <span className="font-bold">Henri Vervaeke</span> on drums and{" "}
                <span className="font-bold">Frederik Everaert</span> on keys,
                the foursome has developed a sound of its own that cannot be
                found on every average concert night. With influences from
                artists such as{" "}
                <span className="font-bold">David Bowie, Arctic Monkeys</span>{" "}
                and <span className="font-bold">Warhaus</span>, they bring an
                energetic live show on which standing still becomes very
                difficult.
              </p>
              <div className="hidden lg:block">
                <Links />
              </div>
            </div>
            <div className="flex flex-col justify-between col-span-12 space-y-8 lg:col-span-6">
              <a
                target="_blank"
                className=""
                href="https://www.dansendeberen.be/2021/10/26/the-black-gasolines-it-took-a-lifetime-theater-in-je-oor/"
              >
                <p className="font-title text-primary">
                  IN A NUTSHELL, IT TOOK A LIFETIME CAN MAKE YOU FEEL LIKE YOU
                  ARE LISTENING TO{" "}
                  <span className="font-bold">
                    A MIX OF TRANQUILITY BASE HOTEL & CASINO AND DAVID BOWIE
                  </span>
                </p>
                <p>- Dansende Beren (★★★★)</p>
              </a>
              <a
                target="_blank"
                href="https://www.thegate.ca/music/054274/theatrical-art-rockers-the-black-gasolines-unleash-dynamite-new-single-in-furthermore/"
              >
                <p className="font-title text-primary">
                  ‘FURTHERMORE‘ BOUNDS OF THE SPEAKERS, WITH RICH USE OF
                  SAXOPHONE, PRODUCTION ELEMENTS, AND HARMONIES; A{" "}
                  <span className="font-bold">
                    MODERN TAKE ON THE ELECTRIFYING SOUNDSCAPE OF THE 70S AND
                    80S
                  </span>
                  .
                </p>
                <p>- The Gate (CA)</p>
              </a>
              <a
                target="_blank"
                href="https://luminousdash.be/reviews/the-black-gasolines-it-took-a-lifetime/"
              >
                <p className="font-title text-primary">
                  THIS DEBUT ALBUM IS{" "}
                  <span className="font-bold">
                    A VERY PUNISHING CALLING CARD
                  </span>{" "}
                  THAT THEY SHOULD TAKE GREAT PRIDE IN.
                </p>
                <p>- Luminous Dash</p>
              </a>
              <a
                target="_blank"
                href="https://www.thatericalper.com/2022/03/23/theatrical-art-rockers-the-black-gasolines-unleash-dynamite-new-single-in-furthermore/"
              >
                <p className="font-title text-primary">
                  THE BLACK GASOLINES CHASES THE LISTENER INTO{" "}
                  <span className="font-bold">
                    A LABYRINTH OF BIG SOUNDS AND DELECTABLE LYRICS
                  </span>
                  . YOU‘RE SINGING ALONG AS MUCH AS YOU‘RE COMPLETELY LOST IN
                  THE JOURNEY OF THE SONG.{" "}
                </p>
                <p>- Luminous Dash</p>
              </a>
            </div>
            <div className="block lg:hidden">
              <Links />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
