const Spotify = ({ color = "#4d3825", width = "21.89", height = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={!height && width}
      height={height}
      viewBox="0 0 22.887 22.887"
    >
      <path
        id="spotify"
        d="M10.944,8A10.944,10.944,0,1,0,21.887,18.944,10.947,10.947,0,0,0,10.944,8Zm4.444,16.1a.842.842,0,0,1-.472-.159c-2.754-1.659-5.957-1.73-9.121-1.081a2.85,2.85,0,0,1-.525.115.687.687,0,0,1-.7-.7.7.7,0,0,1,.6-.741c3.614-.8,7.308-.728,10.458,1.156a.746.746,0,0,1,.428.728.667.667,0,0,1-.671.68Zm1.187-2.895a1.09,1.09,0,0,1-.543-.185A14.3,14.3,0,0,0,5.5,19.725a1.817,1.817,0,0,1-.525.115.857.857,0,0,1-.856-.856.868.868,0,0,1,.684-.913,14.843,14.843,0,0,1,4.316-.6,15.447,15.447,0,0,1,7.811,2.008.92.92,0,0,1,.5.869A.855.855,0,0,1,16.574,21.207Zm1.368-3.363a1.015,1.015,0,0,1-.569-.172c-3.142-1.875-8.759-2.326-12.4-1.311a2.1,2.1,0,0,1-.569.115A1.018,1.018,0,0,1,3.38,15.436a1.043,1.043,0,0,1,.768-1.055,18.5,18.5,0,0,1,5.185-.671A18.637,18.637,0,0,1,18.4,15.819a1.036,1.036,0,0,1,.569,1,1.022,1.022,0,0,1-1.024,1.028Z"
        transform="translate(0 -8)"
        fill={color}
      />
    </svg>
  );
};

export default Spotify;
