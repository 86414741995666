import Musicfront from "../icons/musicfront";
import Spotify from "../icons/spotify";
import AppleMusic from "../icons/appleMusic";
import Youtube from "../icons/youtube";
import Facebook from "../icons/facebook";
import Instagram from "../icons/instagram";

const Header = () => {
  const scrollTo = (id, block) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
      block: block,
      inline: "nearest",
    });
  };

  return (
    <div
      className="relative h-screen py-16 bg-center bg-no-repeat bg-cover"
      style={{ backgroundImage: "url(/images/header.webp)" }}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-black/30 to-secundary" />
      <div className="container relative flex flex-col items-center justify-between h-full">
        <div className="flex flex-col items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="352.481"
            height="117.8"
            viewBox="0 0 352.481 117.8"
          >
            <g id="Group_17" transform="translate(-93.9 -1123.2)">
              <g
                id="Laag_1_00000020397369815599142510000002836647739474222267_"
                transform="translate(93.9 1123.2)"
              >
                <g id="Group_2" transform="translate(0 34.917)">
                  <g id="Group_1">
                    <path
                      id="Path_1"
                      d="M140.855,1197.036v12.772H154.27v11.578a26.706,26.706,0,0,1-20.123,9.1c-14.794,0-26.831-8.27-26.831-27.934,0-17.642,11.945-27.934,26.831-27.934a25.918,25.918,0,0,1,6.524.827v-13.691a38.263,38.263,0,0,0-6.524-.552c-22.237,0-40.247,17.459-40.247,41.441,0,26.372,16.907,41.442,38.5,41.442,9.556,0,16.356-2.3,22.88-8.362l.735,7.627h11.762V1197.22H140.855Z"
                      transform="translate(-93.9 -1161.2)"
                      fill="#e9e8e4"
                    />
                  </g>
                </g>
                <g id="Group_4" transform="translate(102.455 0.184)">
                  <g id="Group_3">
                    <path
                      id="Path_2"
                      d="M209.994,1125.238v10.2h-2.022v-10.2H205.4V1123.4h7.075v1.838Z"
                      transform="translate(-205.4 -1123.4)"
                      fill="#e9e8e4"
                    />
                  </g>
                </g>
                <g id="Group_6" transform="translate(111.276 0.184)">
                  <g id="Group_5">
                    <path
                      id="Path_3"
                      d="M224.005,1135.437h-2.022v-5.054H216.93v5.054H215V1123.4h2.022v4.962h5.054V1123.4H224.1v12.037Z"
                      transform="translate(-215 -1123.4)"
                      fill="#e9e8e4"
                    />
                  </g>
                </g>
                <g id="Group_8" transform="translate(123.038 0.276)">
                  <g id="Group_7">
                    <path
                      id="Path_4"
                      d="M229.822,1128.462h5.054v2.022h-5.054v3.032h6.065v2.021H227.8V1123.5h8.086v2.021h-6.065Z"
                      transform="translate(-227.8 -1123.5)"
                      fill="#e9e8e4"
                    />
                  </g>
                </g>
                <g id="Group_10" transform="translate(102.179 16.632)">
                  <g id="Group_9">
                    <path
                      id="Path_5"
                      d="M228.439,1178.9v7.259H205.1V1142.7h7.259v36.2Z"
                      transform="translate(-205.1 -1141.413)"
                      fill="#e9e8e4"
                    />
                    <path
                      id="Path_6"
                      d="M243.248,1181l-1.838,5.054H233.6l17.367-44.75,17.367,44.75h-7.81l-1.93-5.054Zm12.956-6.249-5.238-15.069-5.238,15.069Z"
                      transform="translate(-207.412 -1141.3)"
                      fill="#e9e8e4"
                    />
                    <path
                      id="Path_7"
                      d="M307.169,1175.271l5.054,5.146a21.694,21.694,0,0,1-15.345,6.341c-11.945,0-21.777-8.729-21.777-22.329,0-13.14,9.1-22.329,21.777-22.329a21.4,21.4,0,0,1,15.345,6.432l-5.054,5.054a14.848,14.848,0,0,0-10.291-4.227c-8.913,0-14.426,6.34-14.426,15.07,0,9.28,6.432,15.07,14.426,15.07A14.849,14.849,0,0,0,307.169,1175.271Z"
                      transform="translate(-210.779 -1141.365)"
                      fill="#e9e8e4"
                    />
                    <path
                      id="Path_8"
                      d="M357.874,1186.163H348.41l-13.416-16.54-4.135,4.595v11.945H323.6V1142.7h7.259v21.961l17.826-21.961h9.1l-17.918,22.145Z"
                      transform="translate(-214.713 -1141.413)"
                      fill="#e9e8e4"
                    />
                  </g>
                </g>
                <g id="Group_12" transform="translate(55.225)">
                  <g id="Group_11">
                    <path
                      id="Path_9"
                      d="M154,1123.2h23.8c6.065.092,10.567,1.562,13.6,4.594a16.134,16.134,0,0,1,4.594,11.945,13.373,13.373,0,0,1-1.838,7.26,12.71,12.71,0,0,1-2.757,3.124,20.722,20.722,0,0,1-4.5,2.849v.184a12.742,12.742,0,0,1,7.627,5.238,17.743,17.743,0,0,1,2.389,8.913c-.092,5.33-1.746,9.465-4.962,12.589a16.563,16.563,0,0,1-12.129,4.87H154Zm9.281,8.27v17.551H176.7c3.4,0,5.881-.919,7.535-2.665a8.608,8.608,0,0,0,2.481-6.156,8.448,8.448,0,0,0-2.481-6.248c-1.562-1.562-4.135-2.389-7.535-2.481Zm0,25.821v18.469h14.334c3.492-.092,5.973-1.011,7.627-2.848a9.177,9.177,0,0,0,2.389-6.432,9.559,9.559,0,0,0-2.389-6.524c-1.562-1.746-4.135-2.665-7.627-2.757H163.281Z"
                      transform="translate(-154 -1123.2)"
                      fill="#e9e8e4"
                    />
                  </g>
                </g>
                <g id="Group_14" transform="translate(79.667 72.224)">
                  <g id="Group_13">
                    <path
                      id="Path_10"
                      d="M190.248,1241.5l-1.838,5.054H180.6l17.367-44.749,17.367,44.749h-7.81l-1.93-5.054Zm12.864-6.34-5.238-15.07-5.238,15.07Z"
                      transform="translate(-180.6 -1201.8)"
                      fill="#e9e8e4"
                    />
                    <path
                      id="Path_11"
                      d="M222,1234.469h7.259c.092,3.584,3.124,5.421,6.34,5.421,3.4,0,6.34-2.113,6.34-5.513,0-3.675-3.4-5.973-6.34-6.065a16.084,16.084,0,0,1-6.524-1.47A11.636,11.636,0,0,1,222,1215.724c0-8.27,6.616-13.324,13.6-13.324,7.535,0,13.324,5.054,13.416,12.5h-7.167c-.092-3.584-2.94-5.238-6.248-5.238-3.4,0-6.34,2.022-6.34,6.065,0,3.583,3.124,5.513,6.34,5.513a15.668,15.668,0,0,1,6.432,1.47c4.594,2.113,7.075,6.524,7.075,11.67,0,8.362-6.524,12.773-13.507,12.773C228.064,1246.966,222.091,1242.647,222,1234.469Z"
                      transform="translate(-183.958 -1201.849)"
                      fill="#e9e8e4"
                    />
                    <path
                      id="Path_12"
                      d="M330.339,1239.3v7.259H307V1203.1h7.259v36.2Z"
                      transform="translate(-190.854 -1201.905)"
                      fill="#e9e8e4"
                    />
                    <path
                      id="Path_13"
                      d="M341,1203.1h7.259v43.463H341Z"
                      transform="translate(-193.612 -1201.905)"
                      fill="#e9e8e4"
                    />
                    <path
                      id="Path_14"
                      d="M388.653,1246.563,367.059,1215.6v30.966H359.8V1203.1h7.351l21.685,30.966V1203.1H396v43.463Z"
                      transform="translate(-195.137 -1201.905)"
                      fill="#e9e8e4"
                    />
                    <path
                      id="Path_15"
                      d="M417.159,1221.194h18.1v7.167h-18.1V1239.2h21.777v7.259H409.9V1203h29.037v7.259H417.159Z"
                      transform="translate(-199.201 -1201.897)"
                      fill="#e9e8e4"
                    />
                    <path
                      id="Path_16"
                      d="M448,1234.469h7.259c.092,3.584,3.124,5.421,6.34,5.421,3.4,0,6.34-2.113,6.34-5.513,0-3.675-3.4-5.973-6.34-6.065a16.084,16.084,0,0,1-6.524-1.47A11.636,11.636,0,0,1,448,1215.724c0-8.27,6.616-13.324,13.6-13.324,7.535,0,13.324,5.054,13.416,12.5h-7.167c-.092-3.584-2.94-5.238-6.248-5.238-3.4,0-6.34,2.022-6.34,6.065,0,3.583,3.124,5.513,6.34,5.513a15.668,15.668,0,0,1,6.432,1.47c4.594,2.113,7.075,6.524,7.075,11.67,0,8.362-6.524,12.773-13.508,12.773C454.064,1246.966,448.091,1242.647,448,1234.469Z"
                      transform="translate(-202.292 -1201.849)"
                      fill="#e9e8e4"
                    />
                  </g>
                </g>
              </g>
              <g
                id="Laag_2_00000002380038977179335020000013157782768938329532_"
                transform="translate(243.953 1199.52)"
              >
                <g id="Group_16" transform="translate(0)">
                  <g id="Group_15">
                    <path
                      id="Path_17"
                      d="M267.043,1222.377a10.9,10.9,0,1,1,17.091,12.221l7.535,5.33A19.886,19.886,0,0,0,263,1212.361a19.622,19.622,0,0,0-3.4,4.778Z"
                      transform="translate(-257.395 -1206.258)"
                      fill="#e9e8e4"
                    />
                    <path
                      id="Path_18"
                      d="M257.2,1227.1v.735a19.921,19.921,0,0,0,19.94,19.94,19.121,19.121,0,0,0,7.167-1.378Z"
                      transform="translate(-257.2 -1207.949)"
                      fill="#e9e8e4"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <nav className="flex flex-wrap items-center justify-center py-8 space-x-4 text-xl uppercase lg:space-x-8 text-secundary font-title">
            <p
              onClick={() => scrollTo("about", "center")}
              className="cursor-pointer"
            >
              About
            </p>
            <p
              onClick={() => scrollTo("shows", "center")}
              className="cursor-pointer"
            >
              Shows
            </p>
            <p
              onClick={() => scrollTo("music", "center")}
              className="cursor-pointer"
            >
              Music
            </p>
            <a href="https://www.musicfront.app/shops/theblackgasolines">
              Shop
            </a>
            <a href="mailto:info@theblackgasolines.com">Contact</a>
          </nav>
        </div>
        <div>
          <div className="flex items-center mt-4 space-x-8 lg:space-x-16">
            <a href="https://www.instagram.com/theblackgasolines/" className="transition-all duration-300 hover:scale-105">
              <Instagram color="#4d3825" height="30" />
            </a>
            <a href="https://open.spotify.com/artist/0MD4KrWissGUdxRxeaLbBp" className="transition-all duration-300 hover:scale-105">
              <Spotify color="#4d3825" height="30" />
            </a>
            <a href="https://music.apple.com/be/artist/the-black-gasolines" className="transition-all duration-300 hover:scale-105">
              <AppleMusic color="#4d3825" height="30" />
            </a>
            <a href="https://www.youtube.com/@theblackgasolines" className="transition-all duration-300 hover:scale-105">
              <Youtube color="#4d3825" height="30" />
            </a>
            <a href="https://www.musicfront.app/shops/theblackgasolines" className="transition-all duration-300 hover:scale-105">
              <Musicfront color="#4d3825" height="30" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
