import Musicfront from "../icons/musicfront";
import Spotify from "../icons/spotify";
import AppleMusic from "../icons/appleMusic";
import Youtube from "../icons/youtube";
import Facebook from "../icons/facebook";
import Instagram from "../icons/instagram";

const Footer = () => {
  return (
    <footer className="py-8 bg-primary">
      <div className="container flex flex-col items-center justify-between space-y-4 lg:space-y-0 lg:flex-row">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="158.919"
            height="53.111"
            viewBox="0 0 158.919 53.111"
          >
            <g id="Group_17" transform="translate(-93.9 -1123.2)">
              <g
                id="Laag_1_00000020397369815599142510000002836647739474222267_"
                transform="translate(93.9 1123.2)"
              >
                <g id="Group_2" transform="translate(0 15.743)">
                  <g id="Group_1">
                    <path
                      id="Path_1"
                      d="M115.07,1177.357v5.759h6.049v5.22a12.041,12.041,0,0,1-9.073,4.1c-6.67,0-12.1-3.729-12.1-12.594,0-7.954,5.386-12.594,12.1-12.594a11.686,11.686,0,0,1,2.941.373v-6.173a17.251,17.251,0,0,0-2.941-.249c-10.026,0-18.146,7.871-18.146,18.684,0,11.89,7.623,18.684,17.358,18.684,4.309,0,7.374-1.036,10.316-3.77l.331,3.439h5.3v-20.8H115.07Z"
                      transform="translate(-93.9 -1161.2)"
                      fill="#f5f5e7"
                    />
                  </g>
                </g>
                <g id="Group_4" transform="translate(46.192 0.083)">
                  <g id="Group_3">
                    <path
                      id="Path_2"
                      d="M207.471,1124.228v4.6h-.911v-4.6H205.4v-.828h3.19v.828Z"
                      transform="translate(-205.4 -1123.4)"
                      fill="#f5f5e7"
                    />
                  </g>
                </g>
                <g id="Group_6" transform="translate(50.17 0.083)">
                  <g id="Group_5">
                    <path
                      id="Path_3"
                      d="M219.06,1128.827h-.911v-2.278H215.87v2.278H215V1123.4h.911v2.237h2.279V1123.4h.911v5.427Z"
                      transform="translate(-215 -1123.4)"
                      fill="#f5f5e7"
                    />
                  </g>
                </g>
                <g id="Group_8" transform="translate(55.472 0.124)">
                  <g id="Group_7">
                    <path
                      id="Path_4"
                      d="M228.711,1125.737h2.279v.911h-2.279v1.367h2.734v.911H227.8V1123.5h3.646v.911h-2.734Z"
                      transform="translate(-227.8 -1123.5)"
                      fill="#f5f5e7"
                    />
                  </g>
                </g>
                <g id="Group_10" transform="translate(46.068 7.499)">
                  <g id="Group_9">
                    <path
                      id="Path_5"
                      d="M215.623,1159.023v3.273H205.1v-19.6h3.273v16.323Z"
                      transform="translate(-205.1 -1142.12)"
                      fill="#f5f5e7"
                    />
                    <path
                      id="Path_6"
                      d="M237.95,1159.2l-.829,2.279H233.6l7.83-20.176,7.83,20.176h-3.521l-.87-2.279Zm5.841-2.817-2.361-6.794-2.361,6.794Z"
                      transform="translate(-221.793 -1141.3)"
                      fill="#f5f5e7"
                    />
                    <path
                      id="Path_7"
                      d="M289.558,1157.055l2.279,2.32a9.781,9.781,0,0,1-6.918,2.859,9.621,9.621,0,0,1-9.818-10.067c0-5.924,4.1-10.067,9.818-10.067a9.649,9.649,0,0,1,6.918,2.9l-2.279,2.279a6.694,6.694,0,0,0-4.64-1.906c-4.019,0-6.5,2.859-6.5,6.794a6.389,6.389,0,0,0,6.5,6.794A6.7,6.7,0,0,0,289.558,1157.055Z"
                      transform="translate(-246.1 -1141.768)"
                      fill="#f5f5e7"
                    />
                    <path
                      id="Path_8"
                      d="M339.053,1162.3h-4.267l-6.049-7.457-1.864,2.072v5.386H323.6v-19.6h3.273v9.9l8.037-9.9h4.1l-8.079,9.984Z"
                      transform="translate(-274.508 -1142.12)"
                      fill="#f5f5e7"
                    />
                  </g>
                </g>
                <g id="Group_12" transform="translate(24.898)">
                  <g id="Group_11">
                    <path
                      id="Path_9"
                      d="M154,1123.2h10.73c2.734.041,4.764.7,6.131,2.072a7.274,7.274,0,0,1,2.071,5.385,6.029,6.029,0,0,1-.829,3.273,5.733,5.733,0,0,1-1.243,1.408,9.341,9.341,0,0,1-2.03,1.284v.083a5.745,5.745,0,0,1,3.439,2.361,8,8,0,0,1,1.077,4.018,7.7,7.7,0,0,1-2.237,5.676,7.468,7.468,0,0,1-5.469,2.2H154Zm4.184,3.729v7.913h6.048a4.457,4.457,0,0,0,3.4-1.2,3.881,3.881,0,0,0,1.119-2.776,3.808,3.808,0,0,0-1.119-2.817,4.9,4.9,0,0,0-3.4-1.119Zm0,11.641v8.327h6.463a4.522,4.522,0,0,0,3.439-1.284,4.137,4.137,0,0,0,1.077-2.9,4.31,4.31,0,0,0-1.077-2.941,4.6,4.6,0,0,0-3.439-1.243h-6.463Z"
                      transform="translate(-154 -1123.2)"
                      fill="#f5f5e7"
                    />
                  </g>
                </g>
                <g id="Group_14" transform="translate(35.918 32.563)">
                  <g id="Group_13">
                    <path
                      id="Path_10"
                      d="M184.95,1219.7l-.829,2.279H180.6l7.83-20.176,7.83,20.176h-3.521l-.87-2.279Zm5.8-2.858-2.361-6.794-2.361,6.794Z"
                      transform="translate(-180.6 -1201.8)"
                      fill="#f5f5e7"
                    />
                    <path
                      id="Path_11"
                      d="M221.995,1216.859h3.273a2.89,2.89,0,0,0,5.717-.041,2.892,2.892,0,0,0-2.859-2.734,7.254,7.254,0,0,1-2.941-.663,5.246,5.246,0,0,1-3.19-5.013,5.955,5.955,0,0,1,6.131-6.007c3.4,0,6.007,2.278,6.048,5.634h-3.231c-.041-1.616-1.326-2.361-2.817-2.361a2.639,2.639,0,0,0-2.859,2.734,2.611,2.611,0,0,0,2.859,2.486,7.063,7.063,0,0,1,2.9.663,5.553,5.553,0,0,1,3.19,5.261c0,3.77-2.941,5.759-6.09,5.759C224.729,1222.493,222.036,1220.546,221.995,1216.859Z"
                      transform="translate(-204.843 -1202.152)"
                      fill="#f5f5e7"
                    />
                    <path
                      id="Path_12"
                      d="M317.523,1219.423v3.273H307v-19.6h3.273v16.323Z"
                      transform="translate(-254.635 -1202.561)"
                      fill="#f5f5e7"
                    />
                    <path
                      id="Path_13"
                      d="M341,1203.1h3.273v19.6H341Z"
                      transform="translate(-274.549 -1202.561)"
                      fill="#f5f5e7"
                    />
                    <path
                      id="Path_14"
                      d="M372.808,1222.7l-9.736-13.961V1222.7H359.8v-19.6h3.314l9.777,13.961V1203.1h3.231v19.6Z"
                      transform="translate(-285.561 -1202.561)"
                      fill="#f5f5e7"
                    />
                    <path
                      id="Path_15"
                      d="M413.173,1211.2h8.161v3.231h-8.161v4.889h9.818v3.273H409.9V1203h13.091v3.273h-9.818Z"
                      transform="translate(-314.905 -1202.503)"
                      fill="#f5f5e7"
                    />
                    <path
                      id="Path_16"
                      d="M447.995,1216.859h3.273a2.89,2.89,0,0,0,5.717-.041,2.892,2.892,0,0,0-2.859-2.734,7.254,7.254,0,0,1-2.941-.663,5.246,5.246,0,0,1-3.19-5.013,5.955,5.955,0,0,1,6.131-6.007c3.4,0,6.007,2.278,6.048,5.634h-3.231c-.041-1.616-1.326-2.361-2.817-2.361a2.639,2.639,0,0,0-2.859,2.734,2.611,2.611,0,0,0,2.859,2.486,7.064,7.064,0,0,1,2.9.663,5.553,5.553,0,0,1,3.19,5.261c0,3.77-2.941,5.759-6.09,5.759C450.729,1222.493,448.036,1220.546,447.995,1216.859Z"
                      transform="translate(-337.216 -1202.152)"
                      fill="#f5f5e7"
                    />
                  </g>
                </g>
              </g>
              <g
                id="Laag_2_00000002380038977179335020000013157782768938329532_"
                transform="translate(161.552 1157.609)"
              >
                <g id="Group_16" transform="translate(0)">
                  <g id="Group_15">
                    <path
                      id="Path_17"
                      d="M262.956,1213.525a4.915,4.915,0,1,1,7.706,5.51l3.4,2.4a8.966,8.966,0,0,0-12.926-12.429,8.849,8.849,0,0,0-1.533,2.154Z"
                      transform="translate(-258.606 -1206.258)"
                      fill="#f5f5e7"
                    />
                    <path
                      id="Path_18"
                      d="M257.2,1227.1v.331a8.982,8.982,0,0,0,8.99,8.99,8.62,8.62,0,0,0,3.231-.621Z"
                      transform="translate(-257.2 -1218.465)"
                      fill="#f5f5e7"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <p className="hidden mt-4 text-sm text-center lg:block text-secundary">Design by <a href="mailto:lukas.debeerst@icloud.com">Lukas Debeerst</a> and <a href="https://www.instagram.com/yeswewilke/">Jolien Wilke</a>.</p>
        </div>
        <div className="flex flex-col items-end">
          <a href="mailTo:info@theblackgasolines.com" className="uppercase font-title text-secundary">info@theblackgasolines.com</a>
          <div className="flex items-center mt-4 space-x-6">
            <a href="https://www.facebook.com/blackgasolines" className="transition-all duration-300 hover:scale-105">
              <Facebook color="#e9e8e4" />
            </a>
            <a href="https://www.instagram.com/theblackgasolines/" className="transition-all duration-300 hover:scale-105">
              <Instagram color="#e9e8e4" />
            </a>
            <a href="https://open.spotify.com/artist/0MD4KrWissGUdxRxeaLbBp" className="transition-all duration-300 hover:scale-105">
              <Spotify color="#e9e8e4" />
            </a>
            <a href="https://music.apple.com/be/artist/the-black-gasolines" className="transition-all duration-300 hover:scale-105">
              <AppleMusic color="#e9e8e4" />
            </a>
            <a href="https://www.youtube.com/@theblackgasolines" className="transition-all duration-300 hover:scale-105">
              <Youtube color="#e9e8e4" />
            </a>
            <a href="https://www.musicfront.app/shops/theblackgasolines" className="transition-all duration-300 hover:scale-105">
              <Musicfront color="#e9e8e4" />
            </a>
          </div>
        </div>
        <p className="mt-4 text-sm text-center lg:hidden text-secundary">Designed by <a href="mailto:lukas.debeerst@icloud.com">Lukas Debeerst</a> and <a href="https://www.instagram.com/yeswewilke/">Jolien Wilke</a>.</p>
      </div>
    </footer>
  );
};

export default Footer;
