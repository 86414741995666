import './App.css';
import Releases from './components/releases';
import Shows from './components/shows';
import About from './components/about';
import Footer from './components/footer';
import Header from './components/header';

function App() {
  return (
    <div>
      <Header />
      <Releases />
      <Shows />
      <About />
      <Footer />
    </div>
  );
}

export default App;
