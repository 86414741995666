const Facebook = ({ color = "#4d3825", width = "11.723", height = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={!height && width}
      height={height}
      viewBox="0 0 11.723 21.887"
    >
      <path
        id="facebook-f"
        d="M33.844,12.312l.608-3.961h-3.8V5.78a1.981,1.981,0,0,1,2.233-2.14h1.728V.268A21.071,21.071,0,0,0,31.545,0c-3.13,0-5.176,1.9-5.176,5.332V8.351H22.89v3.961h3.479v9.576h4.282V12.312Z"
        transform="translate(-22.89)"
        fill={color}
      />
    </svg>
  );
};

export default Facebook;
