const Instagram = ({ color = "#4d3825", width = "21.892", height = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={!height && width}
      height={height}
      viewBox="0 0 21.892 21.887"
    >
      <path
        id="instagram"
        d="M10.874,37.157a5.612,5.612,0,1,0,5.612,5.612A5.6,5.6,0,0,0,10.874,37.157Zm0,9.26a3.648,3.648,0,1,1,3.648-3.648,3.655,3.655,0,0,1-3.648,3.648Zm7.15-9.489a1.309,1.309,0,1,1-1.309-1.309A1.306,1.306,0,0,1,18.024,36.927Zm3.717,1.328a6.477,6.477,0,0,0-1.768-4.586A6.52,6.52,0,0,0,15.386,31.9c-1.807-.1-7.223-.1-9.03,0A6.511,6.511,0,0,0,1.77,33.665,6.5,6.5,0,0,0,0,38.251c-.1,1.807-.1,7.223,0,9.03A6.477,6.477,0,0,0,1.77,51.867a6.528,6.528,0,0,0,4.586,1.768c1.807.1,7.223.1,9.03,0a6.477,6.477,0,0,0,4.586-1.768,6.52,6.52,0,0,0,1.768-4.586C21.843,45.474,21.843,40.063,21.74,38.256ZM19.406,49.22A3.694,3.694,0,0,1,17.325,51.3c-1.441.571-4.86.44-6.452.44s-5.016.127-6.452-.44A3.694,3.694,0,0,1,2.341,49.22c-.571-1.441-.44-4.86-.44-6.452s-.127-5.016.44-6.452a3.694,3.694,0,0,1,2.081-2.081c1.441-.571,4.86-.44,6.452-.44s5.016-.127,6.452.44a3.694,3.694,0,0,1,2.081,2.081c.571,1.441.44,4.86.44,6.452S19.977,47.784,19.406,49.22Z"
        transform="translate(0.075 -31.825)"
        fill={color}
      />
    </svg>
  );
};

export default Instagram;
