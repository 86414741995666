const Youtube = ({ color = "#4d3825", width = "31.129", height = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={!height && width}
      height={height}
      viewBox="0 0 31.129 21.888"
    >
      <path
        id="youtube"
        d="M45.412,67.425a3.911,3.911,0,0,0-2.752-2.77C40.232,64,30.5,64,30.5,64s-9.734,0-12.162.655a3.912,3.912,0,0,0-2.752,2.77,44.04,44.04,0,0,0,0,15.083,3.853,3.853,0,0,0,2.752,2.726c2.428.655,12.162.655,12.162.655s9.734,0,12.162-.655a3.853,3.853,0,0,0,2.752-2.726,44.04,44.04,0,0,0,0-15.083Zm-18.1,12.17V70.337l8.136,4.629-8.136,4.628Z"
        transform="translate(-14.933 -64)"
        fill={color}
      />
    </svg>
  );
};

export default Youtube;
