const Musicfront = ({color = "#4d3825", width="17.447", height=false}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={!height && width}
      height={height}
      viewBox="0 0 18.447 22.89"
    >
      <g id="Icons" transform="translate(-215.7 -142.4)">
        <g id="Group_7" data-name="Group 7" transform="translate(215.7 142.4)">
          <g id="Group_6" data-name="Group 6" transform="translate(0 0)">
            <g id="Group_5" data-name="Group 5">
              <g id="Group_4" data-name="Group 4">
                <g id="Group_3" data-name="Group 3">
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M233.147,151.391v-.083c0-.174-.009-.343-.021-.514,0-.028,0-.055,0-.086a10.47,10.47,0,0,0-1.486-4.927l-.046-.073c-.095-.15-.193-.294-.3-.434-.015-.024-.034-.046-.052-.07a6.819,6.819,0,0,0-5.511-2.8h0a.474.474,0,0,0-.067,0,.832.832,0,0,0-.086,0h0a7.646,7.646,0,0,0-1.122.092c-.067.012-.135.021-.2.034h0a9.475,9.475,0,0,0-5.523,3.37,11.973,11.973,0,0,0-1.22,1.789q-.046.078-.083.156s0,0-.006,0a13.169,13.169,0,0,0-.618,1.309v0c-.018.043-.037.089-.052.132a15.9,15.9,0,0,0-1.052,5.8,12.868,12.868,0,0,0,.2,2.3c0,.021.006.04.009.058.052.275.11.541.177.8.006.018.009.037.015.052a8.455,8.455,0,0,0,2.8,4.529.265.265,0,0,0,.031.021c.135.107.275.208.419.306l.024.015a6.815,6.815,0,0,0,3.81,1.119,7.737,7.737,0,0,0,1.749-.2h0l.076-.018h0a7.794,7.794,0,0,0,.89-.266c.034-.012.07-.024.1-.04h0a10.294,10.294,0,0,0,5.083-4.339h0c.027-.046.055-.092.083-.135.177-.294.339-.6.5-.914.018-.04.04-.076.058-.116v0a15.376,15.376,0,0,0,1.416-6.661C233.147,151.532,233.147,151.461,233.147,151.391Zm-9.96,10.856a2.5,2.5,0,0,1-2.333-1.508c-.006-.012-.015-.024-.021-.04a4.857,4.857,0,0,1-.235-.554l-.018-.046a11.83,11.83,0,0,1-.5-3.823,20.6,20.6,0,0,1,.376-3.838l.018-.1c.061-.321.132-.636.208-.951.006-.034.015-.064.024-.1v0a17.986,17.986,0,0,1,1.012-3c1.1-2.471,2.5-3.832,3.951-3.832a2.622,2.622,0,0,1,2.52,1.914c.009.021.018.046.028.07.058.15.113.312.162.48.006.024.015.052.021.076a12.919,12.919,0,0,1,.382,3.428,19.827,19.827,0,0,1-.15,2.385c0,.031-.006.061-.012.092-.024.208-.052.416-.086.621-.006.031-.009.061-.015.092v0a19.435,19.435,0,0,1-1.376,4.8C226.036,160.886,224.633,162.247,223.186,162.247Z"
                    transform="translate(-215.7 -142.4)"
                    fill={color}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Musicfront;
